.likert {
    list-style: none;
    width: 100%;
    margin-top: 20px;
    padding: 0;
    display: block;
    border-bottom: 2px solid #efefef;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    user-select: none;
}
.likert:last-of-type {
    border-bottom: 0;
}
.likert li {
    display: inline-block;
    width: 24%;
    text-align: center;
    vertical-align: top;
    font-size: 0.8rem;
    color: #444;
}

.likert li:not(:last-child)::before {
    content: '';
    position: relative;
    left: 50%;
    display: block;
    background-color: #000;
    height: 2px;
    width: 100%;
}


.likert label input {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

.likert label {
    cursor: pointer;
}


/* Custom Radio Button */
.likert span {
    display: block;
    position: relative;
    left: 50%;
    margin-top: -11px;
    margin-left: -8px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 1px black;
    border-radius: 50%;
}
.likert label:hover input:not(:checked) ~ span {
    background-color: #ccc;
}
.likert input:checked ~ span {
    background-color: #4da4a8;
    border: solid 1px #4da4a8;
}


/* Segment Bar Styling */
.likert li.active-segment::before {
    background-color: #4da4a8 !important;
    height: 4px;
    top: -2px;
}
.likert li.active-segment span {
    margin-top: -14px;
}



/* Validation */
.was-validated .likert.is-invalid li span {
    border-color: red;
}

.was-validated .likert.is-invalid li {
    color: red;
}

.was-validated .likert.is-invalid li:not(:last-child)::before {
    background-color: red;
}