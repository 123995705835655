.eight_scale {
    list-style: none;
    width: 100%;
    margin-top: 20px;
    padding: 0;
    display: block;
    border-bottom: 2px solid #efefef;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    user-select: none;
}
.eight_scale:last-of-type {
    border-bottom: 0;
}
.eight_scale li {
    display: inline-block;
    width: 12%;
    text-align: center;
    vertical-align: baseline;
}
.eight_scale li:not(:last-child):before {
    content: '';
    position: relative;
    left: 50%;
    display: block;
    background-color: #000;
    height: 2px;
    width: 100%;
}


.eight_scale label input {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

.eight_scale label {
    cursor: pointer;
    font-weight: bold;
}


/* Custom Radio Button */
.eight_scale span {
    display: block;
    position: relative;
    left: 50%;
    margin-top: -11px;
    margin-left: -10px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 1px black;
    border-radius: 50%;
}
.eight_scale label:hover input:not(:checked) ~ span {
    background-color: #ccc;
}
.eight_scale input:checked ~ span {
    background-color: #4da4a8;
    border: solid 1px #4da4a8;
}


/* Segment Bar Styling */
.eight_scale li.active-segment:before {
    background-color: #4da4a8 !important;
    height: 4px;
    top: -2px;
}
.eight_scale li.active-segment span {
    margin-top: -14px;
}



/* Validation */
.was-validated .eight_scale.is-invalid li span {
    border-color: red;
}

.was-validated .eight_scale.is-invalid li {
    color: red;
}

.was-validated .eight_scale.is-invalid li:not(:last-child)::before {
    background-color: red;
}