.provider-card {
    cursor: pointer;
    margin-bottom: 5px;
}

.provider-card .details {
    display: inline-block;
}

.provider-select input { display:none; }
input:checked + .provider-card {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

form.needs-validation.was-validated:invalid .provider-card {
    outline: none;
    border-color: $red;
    box-shadow: 0 0 10px $red;
}

form.needs-validation.was-validated:invalid .invalid-feedback {
    display: block;
}